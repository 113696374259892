<template>
    <div>
        <qy-top-nav title="忘记登录密码" />
		<div class="container">
        <van-cell-group style="margin-top:40px;">
            <van-field v-model="phone"  type="tel" label="手机号" label-width="4.2em" placeholder="请输入手机号" autocomplete="off" :rules="[{ required: true, message: '请输入手机号'}]" :disabled="disablePhone"/>
            <van-field v-model="captcha"  label="验证码" label-width="4.2em" placeholder="请输入验证码" autocomplete="off"  :rules="[{ required: true, message: '请输入验证码'}]">
                <template #button>
                    <van-button type="primary" @click="sendPhoneCode" size="small" :disabled="disable" id="sendBtn"><a v-text="smsText"></a></van-button>
                </template>
            </van-field>
            <van-field v-model="newPassword"  label="新密码" type="password" label-width="4.2em" placeholder="请输入新密码" autocomplete="off" :rules="[{ required: true, message: '请输入新密码'}]"/> <br/>
            <van-button type="info" block round  @click="forgetPassword">确认修改</van-button>
        </van-cell-group>
		</div>
    </div>
</template>

<script>
    import request from '@/utils/request'
    import { Toast } from 'vant';
    export default {
        name: "ForgetPwd",
        data() {
            return {
                phone:'',
                captcha:'',
                smsText:"获取验证码",
                disable:false,
                disablePhone:false,
                timer:null,
                times:120,
                newPassword:'',
            }
        },
        created(){
          request.post("/api/login/forgetPwdPhone").then(res => {
              if (res.code == 0){
                 this.phone = res.data;
                 if (this.phone && this.phone.length > 0){
                     this.disablePhone = true;
                 }
              }else {

              }
          });
        },
        methods: {
            sendPhoneCode(){
                let phone = this.phone;
                if (!phone) {
                    Toast.fail('手机号码不能空');
                    return
                }
                let numbers = /^1\d{10}$/;
                if (!numbers.test(phone) || phone.length == 0) {
                    Toast.fail('手机号码格式错误');
                    return
                }
                this.disable=true;
                request.post('/api/common/sendPhoneCode', {
                    phone: phone,
                    type:"smsForgetPwd"
                }).then(response =>{
                    if (response.code == 0) {
                        //计时开始
                        let that = this;
                        that.timer = setInterval(function() {
                            that.times--;
                            if (that.times <= 0) {
                                that.smsText = '获取验证码';
                                clearInterval(that.timer);
                                that.times = 60;
                                that.disable = false;
                            } else {
                                that.smsText =  that.times + '秒后重试';
                                that.disable = true;
                            }
                        }, 1000);
                    }else {
                        Toast.fail(response.msg);
                    }
                });
            },
            forgetPassword(){
                request.post("/api/login/forgetPassword",{
                    phone:this.phone,
                    captcha:this.captcha,
                    newPassword:this.newPassword
                }).then(response => {
                    if(response.code == 0){
                        //重新登录........
                        this.$router.push("/login");
                    }else {
                        return Toast.fail(response.msg);
                    }
                });
            }
        }
    }
</script>
<style type="text/css" scoped="">
	.van-button--info{

	background: -webkit-linear-gradient(left,#ff6034,#ee0a24);
    background: linear-gradient(to right,#ff6034,#ee0a24);
	border:0px;
	width:95%;
	margin:0px auto;
}
.van-button--primary{color:#ff6034;background:none;border:0px;font-size:14px;}
.container {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #fff;
    padding: 10% 5%;
    box-sizing: border-box;
}
</style>